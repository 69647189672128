export const attributes = {
  tableWrapper: 'analytics-group-list',
  assignmentGroup: 'assignment-group',
  assignmentEventName: 'assignment-event-name',
  assignmentEventType: 'assignment-event-type',
  assignmentEventEntityTitle: 'assignment-event-entity-title',
  analyticsButtonStatus: 'analytics-button-status',
  analyticsButtonUpdate: 'analytics-button-update',
  analyticsButtonDelete: 'analytics-button-delete',
  analyticsGroupStart: 'analytics-group-button-status-start',
  analyticsGroupStop: 'analytics-group-button-status-stop',
  deleteAnalyticBtnInDialog: 'delete-analytic-button-in-dialog',
  cancelBtnInDialog: 'cancel-button-in-dialog',
  messegeDeleteAnalyticInDialog: 'message-delete-analytic-in-dialog'
}
